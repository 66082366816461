
const filters = {
    curreny: function (amount) {
      return amount ? parseFloat(amount).toFixed(2) : "0.00";
   
    }
  }
  export default (vm) => {
    Object.keys(filters).forEach(key => {
      vm.filter(key, filters[key])
    })
  }